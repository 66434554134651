<template>
  <website>
    <wallet-history-card />
  </website>
</template>

<script>
  import Website from '../templates/Website/Website.vue'
  import WalletHistoryCard from '../components/Wallet/WalletHistoryCard.vue'

  export default {
    name: 'WalletHistory',
    components: {
      Website,
      WalletHistoryCard,
    },
  }
</script>
