<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>{{ walletAmount }}</v-list-item-title>
      <v-list-item-subtitle>{{ walletDesc }}</v-list-item-subtitle>
      <v-list-item-subtitle>{{ walletTimestamp }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import TimestampFormatter from '@peynman/press-vue-core/mixins/TimestampFormatter'
  export default {
    name: 'WalletHistoryItem',
    mixins: [
      TimestampFormatter,
    ],
    props: {
      wallet: Object,
    },
    computed: {
      walletAmount () {
        return this.$t('components.website.productCard.price', {
          currency: this.$store.getters['banking/currentCurrencyTitle'],
          amount: this.$n(this.wallet?.amount, 'decimal'),
        })
      },
      walletDesc () {
        return this.wallet?.data.description
      },
      walletTimestamp () {
        return this.getRelativeDate(this.wallet?.created_at)
      },
    },
  }
</script>
