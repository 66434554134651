<template>
  <v-card
    :color="theme.website.userCart.card.color"
    :light="theme.website.userCart.card.light"
    :dark="theme.website.userCart.card.dark"
  >
    <v-toolbar>
      <v-toolbar-title>
        <span>{{ $t('components.website.walletCard.title') }}</span>
      </v-toolbar-title>
    </v-toolbar>
    <paginated-list
      :load-promise="getLoadPromiseForPage"
      :load-more-text="$t('components.website.walletCard.loadMore')"
      :empty-text="$t('components.website.walletCard.empty')"
    >
      <template v-slot:item="{item, index}">
        <v-divider v-if="index > 0" />
        <wallet-history-item :wallet="item" />
      </template>
    </paginated-list>
  </v-card>
</template>

<script>
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import PaginatedList from '@peynman/press-vue-admin/components/Inputs/PaginatedList/PaginatedList.vue'
  import WalletHistoryItem from './WalletHistoryItem.vue'

  export default {
    name: 'CartsHistoryCard',
    components: {
      PaginatedList,
      WalletHistoryItem,
    },
    mixins: [
      Themeable,
    ],
    methods: {
      getLoadPromiseForPage (page) {
        return this.$store.dispatch('wallet/fetchWalletTransactions', page)
      },
    },
  }
</script>
